<template>
  <div class="rights_container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>角色权限</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col>
          <el-button type="info" size="mini" @click="addRoleDialogVisible = true">添加角色</el-button>
        </el-col>
      </el-row>
      <el-table :data="tableData" style="width: 100%" class="tableStyle">
        <el-table-column fixed prop="role" label="角色" width="150"></el-table-column>
        <el-table-column prop="remark" label="备注" width="150"></el-table-column>
        <el-table-column prop="roleList" label="权限列表"></el-table-column>
        <el-table-column fixed="right" label="操作" width="200" class="tableEdit">
          <template v-slot="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <i class="el-icon-edit" @click="rightEdit(scope.row)"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <i class="el-icon-delete" @click="deleteRow(scope.$index, tableData)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="添加角色"
        :visible.sync="addRoleDialogVisible"
        width="50%"
        @close="setRightDialogClosed"
        :modal="false"
      >
        <el-form ref="roleFormRef" :model="roleForm" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="roleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="roleForm.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary">立即创建</el-button>
            <el-button>取消</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addRoleDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="allotRights">确定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="分配权限"
        :visible.sync="setRightDialogVisible"
        width="50%"
        :modal="false"
        @close="setRightDialogClosed"
      >
        <el-tree :data="rightsList" :props="treeProps" show-checkbox node-key="id" default-expand-all ref="treeRef">
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setRightDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="allotRights">确定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: 'Rights',
  data() {
    return {
      radio: '',
      setRightDialogVisible: false,
      addRoleDialogVisible: false,
      rightsList: [
        {
          id: 1,
          label: '超级管理员'
        },
        {
          id: 2,
          label: '管理员'
        },
        {
          id: 3,
          label: '普通用户'
        },
        {
          id: 4,
          label: '游客'
        }
      ],
      roleForm: {
        name: '',
        remark: ''
      },
      checkList: ['船只'],
      tableData: [
        {
          role: '超级管理员',
          remark: '可分配所有权限',
          roleList: '临时添加'
        },
        {
          role: '管理员',
          remark: '可分配部分权限',
          roleList: '临时添加'
        },
        {
          role: '普通用户',
          remark: '',
          roleList: '临时添加'
        },
        {
          role: '临时用户',
          remark: '',
          roleList: '临时添加'
        }
      ],
      treeProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  mounted() {},
  // 组件方法
  methods: {
    rightEdit(row) {
      this.setRightDialogVisible = true
    },
    deleteRow(index, rows) {
      rows.splice(index, 1)
    },
    setRightDialogClosed() {},
    allotRights() {}
  }
}
</script>

<style lang="scss" scoped>
.rights_container {
  height: 100%;
  color: #fff;
  .aside_title {
    box-sizing: border-box;
    height: 70px;
    text-align: center;
    line-height: 70px;
    border-bottom: 2px solid rgba(100, 97, 97, 0.8);
    i {
      font-size: 18px;
      padding-right: 10px;
    }
  }
  .home_title {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    width: 180px;
    height: 70px;
    padding: 0 20px;
    line-height: 70px;
    border-top: 2px solid rgba(100, 97, 97, 0.8);
    i {
      font-size: 18px;
      padding-right: 4px;
    }
  }
  .powerCheckList {
    display: flex;
    background-color: #363d45;
    margin: 0px 20px;
    border-radius: 10px;
    .checkListItem {
      box-sizing: border-box;
      padding: 5px 15px;
      border-right: 1px solid #000;
    }
    .Checkbox {
      display: flex;
      flex-direction: column;
    }
  }
  .tableStyle {
    color: #fff;
    .item {
      margin-right: 10px;
    }
  }
}
</style>
